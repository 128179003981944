import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import "./App.css";

import { Header, Footer } from "./layout";
import {
  About,
  Category,
  DocumentList,
  Home,
  Info,
  Instructions,
  CertifierInstructions,
  IntroQuestions,
  Login,
  Language,
  QuestionList,
  Questionnaires,
  Rating,
  Report,
} from "./pages";
import { SimpleLayout, QuestionnaireLayout } from "./layouts";
import { CompanyContext } from "./context/CompanyContext";
import { LanguageContext } from "./context/LanguageContext";
import { getLanguage } from "./utils/utils";

class App extends Component {
  constructor(props) {
    super(props);

    const history = createHistory();

    if (window.location.hostname === "upitnik.poslodavacpartner.org") {
      window.location.replace("https://platform.employerpartner.eu/");
    }

    if (window.location.pathname === "/" && localStorage.getItem("q")) {
      history.push(`/${localStorage.getItem("q")}/about`);
    }

    this.state = {
      companyName: "",
      setCompanyName: this.setCompanyName,
      language: this.getCurrentLanguage(),
      getLanguages: this.getLanguages,
      setLanguage: this.setLanguage,
      history: history,
      showLogout: false,
    };
  }

  setCompanyName = (name) => {
    this.setState({
      companyName: name,
    });
  };

  getLanguages = () => {
    return ["hr", "en", "sr"];
  };

  getCurrentLanguage = () => {
    return getLanguage();
  };

  setLanguage = (language) => {
    localStorage.setItem("language", language);
    window.location.href = `/${language}${window.location.pathname.substring(
      3
    )}`;
  };

  showLogout = (show) => {
    this.setState({
      showLogout: show,
    });
  };

  render() {
    const { language, getLanguages, setLanguage, companyName, setCompanyName } =
      this.state;

    return (
      <LanguageContext.Provider
        value={{
          language: language,
          setLanguage: setLanguage,
          getLanguages: getLanguages,
        }}
      >
        <BrowserRouter basename={language} history={this.state.history}>
          <CompanyContext.Provider
            value={{
              name: companyName,
              setName: setCompanyName,
            }}
          >
            <main className="app">
              <Header />
              <section className="page">
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/hr" component={Language} />
                  <Route exact path="/en" component={Language} />
                  <Route exact path="/sr" component={Language} />
                  <PrivateRoute
                    exact
                    path="/en"
                    component={Language}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/"
                    component={Home}
                    layout="simple"
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/about"
                    component={About}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/questionnaires"
                    component={Questionnaires}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/instructions"
                    component={Instructions}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/certifier-instructions"
                    component={CertifierInstructions}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/intro-questions"
                    component={IntroQuestions}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/info"
                    component={Info}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/rating"
                    component={Rating}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/above-beyond"
                    component={Category}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/category/:slug"
                    component={Category}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/category/:slug/:index"
                    component={Category}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/above-beyond/:slug"
                    component={Category}
                    aboveBeyond={true}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/above-beyond/:slug/:index"
                    component={Category}
                    aboveBeyond={true}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/saved-questions"
                    component={Category}
                    savedQuestions={true}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/documents"
                    component={DocumentList}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/questions"
                    component={QuestionList}
                    showLogout={this.showLogout}
                  />
                  <PrivateRoute
                    exact
                    path="/:id/report"
                    layout="simple"
                    component={Report}
                    showLogout={this.showLogout}
                  />
                </Switch>
              </section>
              <Footer />
            </main>
          </CompanyContext.Provider>
        </BrowserRouter>
      </LanguageContext.Provider>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        rest.layout === "simple" ? (
          <SimpleLayout {...props}>
            <Component {...props} {...rest} />
          </SimpleLayout>
        ) : (
          <QuestionnaireLayout {...props}>
            <Component {...props} {...rest} />
          </QuestionnaireLayout>
        )
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default App;
