import React, { Component } from "react";

export default class ReportOutro extends Component {
  render() {
    return (
      <div className="report__page__wrapper">
        <div className="report__page">
          <div className="report_outro">
            <div className="report_outro__info__selectio">
              <img alt="" src="/assets/logo/logo_selectio.png" />
              <img alt="" src="/assets/logo/logo-inverted.svg" />
            </div>
            <div className="report_outro__content">
              <div
                className="report_outro__info__title"
                dangerouslySetInnerHTML={{ __html: this.props.title }}
              />
              <div className="report_outro__info__content">
                Selectio Solutions d.o.o.
                <br />
                Strojarska cesta 20, Zagreb
                <br />
                <a
                  href="https://employerpartner.eu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  employerpartner.eu
                </a>
                <br />
                <br />
                contact@employerpartner.eu
                <br />
                +385 1 6065 271
              </div>
            </div>
            <div className="report_outro__info__logos">
              <span className="logo_label">{this.props.sponsorsLabel}: </span>
              <img alt="" src="/assets/logo/logo_almacareer.png" />
              <img alt="" src="/assets/logo/logo_mojposao.png" />
              <span className="logo_label">{this.props.partnersLabel}: </span>
              <img alt="" src="/assets/logo/logo_lider.png" />
              <img alt="" src="/assets/logo/logo_algebra.png" />
              <img alt="" src="/assets/logo/logo_infostud.png" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
