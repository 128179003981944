const elc = {
  hr: `<div>
  <h2>Analiza HR sustava iz perspektive zaposlenika</h2>
  <h3><em>Employee Life Cycle</em></h3>
  <br/>
  <p>Poslodavac Partner metodologija je putovanje organizacijom kroz oči vaših zaposlenih.</p>
  <p>Svrha ovog izveštaja je pružiti strukturirani pregled upravljanja ljudskim potencijalima u vašoj organizaciji te sugestije za dalji razvoj ljudskih resursa prilagođene upravo vama.</p>
</div>
  `,
  en: `<div>
    <h2>Let's See What<br/> You Are Made Of.</h2>
    <h3>Employee Life Cycle</h3>
    <br/>
    <p>Employer Partner certification is a journey through the eyes of your employees.</p>
    <p>The purpose of the Final Report is to provide a structured overview of the Human Resources management in your organisation including tailor-made suggestions for further HR development.</p>
  </div>
  `,
  sr: `<div>
    <h2>Analiza HR sistema iz perspektive zaposlih</h2>
    <h3><em>Employee Life Cycle</em></h3>
    <br/>
    <p>Poslodavac Partner metodologija je putovanje organizacijom kroz oči vaših zaposlenih.</p>
    <p>Svrha ovog izveštaja je pružiti strukturirani pregled upravljanja ljudskim potencijalima u vašoj organizaciji te sugestije za dalji razvoj ljudskih resursa prilagođene upravo vama.</p>
  </div>
  `,
};

const info = {
  hr: `
  <h2>Kako bi ponijela certifikat Employer Partner, organizacija treba ostvariti:</h2>
  <p>barem <strong>75% ukupno mogućih bodova</strong>, te najmanje ocjenu 2 na 10 ključnih tema:</p>
  <div class="report__items">
    <ul>
      <li>Selekcija</li>
      <li>Razvoj kompetencija</li>
      <li><em>Leadership</em></li>
      <li>Radna uspješnost</li>
      <li>Uključenost i zadovoljstvo</li>
    </ul>
    <ul>
      <li>Karijera i nasljeđivanje</li>
      <li>Nagrađivanje</li>
      <li>Dobrobit</li>
      <li>HR kapacitet</li>
      <li>Strateško savjetovanje</li>
    </ul>
  </div>
  `,
  en: `<div>
    <h2>To become an Employer Partner,<br/> the organization must achieve:</h2>
    <p>at least <strong>75% of the total score</strong> on 30 HR topics assessed<br/> and reach a quality threshold on 10 key topics:</p>
    <div class="report__items">
      <ul>
        <li>Selection</li>
        <li>Building Competencies</li>
        <li>Leadership</li>
        <li>Performance</li>
        <li>Engagement &amp; Satisfaction</li>
      </ul>
      <ul>
        <li>Career &amp; Succession</li>
        <li>Compensation</li>
        <li>Wellbeing</li>
        <li>HR Capacity</li>
        <li>Strategic Advisory</li>
      </ul>
    </div>
  </div>
  `,
  sr: `<div>
    <h2>Kako bi ponela sertifikat Employer Partner, organizacija treba da ostvari:</h2>
    <p>barem <strong>75% ukupno mogućih bodova</strong>, te najmanje ocenu 2 na 10 ključnih tema:</p>
    <div class="report__items">
      <ul>
        <li>Selekcija</li>
        <li>Razvoj kompetencija</li>
        <li><em>Leadership</em></li>
        <li>Radna uspešnost</li>
        <li>Uključenost i zadovoljstvo</li>
      </ul>
      <ul>
        <li>Karijera i nasleđivanje</li>
        <li>Nagrađivanje</li>
        <li>Dobrobit</li>
        <li>HR kapacitet</li>
        <li>Strateško savetovanje</li>
      </ul>
    </div>
  </div>
  `,
};

const forEmployer = {
  hr: `<div>
  <p>Employer Partner certifikat jedino je nepristrano priznanje poznatih stručnjaka europske HR zajednice za izvrsnost u upravljanju ljudskim resursima.</p>
  <br/>
  <h2>Što dobivate?</h2>
  <ol>
    <li>employer branding i <strong>medijsku vidljivost</strong></li>
    <li>pravo <strong>priznanje</strong> u poslovnom svijetu</li>
    <li><strong>akviziciju</strong> talenata</li>
    <li>HR <strong>benchmark</strong></li>
    <li>objektivnu <strong>procjenu</strong> HR sustava</li>
    <li><strong>sugestije</strong> prilagođene vašem HR timu</li>
  </ol>
</div>
  `,
  en: `<div>
    <p>Certificate Employer Partner is the only impartial recognition from the well-known experts in the European HR community for excellence in Human Resource Management.</p>
    <br/>
    <h2>What's in it for you?</h2>
    <ol>
      <li>employer branding and <strong>media visibility</strong></li>
      <li>true <strong>recognition</strong> in the business world</li>
      <li>talent <strong>acquisition</strong></li>
      <li>HR <strong>benchmark</strong></li>
      <li>objective <strong>assessment</strong> of HR system</li>
      <li>tailor-made <strong>suggestions</strong> for HR team</li>
    </ol>
  </div>
  `,
  sr: `<div>
    <p>Employer Partner sertifikat je jedino nepristrano priznanje poznatih stručnjaka evropske HR zajednice za izvrsnost u upravljanju ljudskim resursima.</p>
    <br/>
    <h2>Šta dobijate?</h2>
    <ol>
      <li>employer branding i <strong>medijsku vidljivost</strong></li>
      <li>pravo <strong>priznanje</strong> u poslovnom svetu</li>
      <li><strong>akviziciju</strong> talenata</li>
      <li>HR <strong>benchmark</strong></li>
      <li>objektivnu <strong>procenu</strong> HR sistema</li>
      <li><strong>sugestije</strong> prilagođene vašem HR timu</li>
    </ol>
  </div>
  `,
};

const whatsNext = {
  hr: `<p>Vaša organizacija zadržava pravo korištenja statusa Poslodavac Partner do <strong style="color:#cc0000">datuma mjeseca, godine</strong>, nakon čega se vaša organizacija treba ponovno prijaviti za proces certifikacije kako bi zadržala status.</p>
  <p>Nakon postizanja standarda u procesu certificiranja, mi ćemo:</p>
  <ul><li><strong><u>Koordinirati ceremoniju dodjele nagrada</u></strong><ul><li>PR tim će Vas kontaktirati u vezi izjave za medije i datuma početka komunikacije.</li></ul></li><li><strong><u>Poslati informativni paket za svoj PR tim</u></strong><ul><li>Naš tim će vam poslati bedž Poslodavac Partner i glavne informacije za PR.</li></ul></li><li><strong><u>Izraditi profil tvrtke na web stranici Poslodavac Partner</u></strong><ul><li>Promicat ćemo vašu organizaciju u regionalnoj poslovnoj zajednici.</li></ul></li><li><strong><u>Aktivirati Poslodavac Partner bedž</u></strong><ul><li>Bedž će biti prikazan na portalu MojPosao.</li></ul></li><li><strong><u>Objaviti vijest u partnerskim medijima</u></strong><ul><li>Objavit ćemo PR članak na portalima Lider i MojPosao.</li></ul></li><li><strong><u>Objaviti post na blogu i/ili intervju</u></strong><ul><li>Ispričat ćemo priču o vašim istaknutim praksama.</li></ul></li><li><strong><u>Podijeliti vijesti putem LinkedIna</u></strong><ul><li>Naši postovi dosežu između 2.000 i 7.000 korisnika po profilu i čitaju ih većinom stručnjaci na razini C (40%).</li></ul></li></ul>
  <p>Poslodavci Partneri najbolji su ambasadori certifikata stoga vas potičemo da podijelite svoj uspjeh!</p>`,
  en: `<p>Your organization reserves the right to use the Employer Partner status until <strong>Month Date, Year</strong>, subsequent to which your organization should apply for the certification process in order to keep the status. </p>
  <p>After reaching the standard in the certification process, we:</p>
  <ul><li><strong><u>Coordinate the award-giving ceremony</u></strong><ul><li>PR team will contact you regarding the media statement and start date for the communication.</li></ul></li><li><strong><u>Send an information package for your PR team</u></strong><ul><li>Our team will send you the Employer Partner badge and main information for PR.</li></ul></li><li><strong><u>Create a company profile on the Employer Partner website</u></strong><ul><li>We will promote your organisation to the regional business community.</li></ul></li><li><strong><u>Activate Employer Partner Badge</u></strong><ul><li>The Badge will be shown on <span style="color:#cc0000">MojPosao/Infostud</span> portal.</li></ul></li><li><strong><u>Publish the news in partner media</u></strong><ul><li>We will publish a PR article on Lider and <span style="color:#cc0000">MojPosao/Infostud</span> portal<span style="color:#cc0000">s</span>.</li></ul></li><li><strong><u>Publish a blog post and/or interview</u></strong><ul><li>We will tell a story about your highlighted practices.</li></ul></li><li><strong><u>Share the news via LinkedIn</u></strong><ul><li>Our posts reach between 2,000 and 7,000 users per profile and are read mostly by c-level experts (40%).</li></ul></li></ul>
  <p>Employer Partners are the best ambassadors of the certificate so we encourage you to share your success!</p>
  `,
  sr: `<p>Vaša organizacija zadržava pravo da koristi status Poslodavac Partner do <strong style="color:#cc0000">datuma ​​meseca, godine</strong>, nakon čega vaša organizacija mora ponovo da se prijavi za proces sertifikacije da bi zadržala status.</p>
  <p>Nakon dostizanja standarda u procesu sertifikacije, mi ćemo:</p>
  <ul><li><strong><u>Koordinirati ceremoniju dodele nagrada</u></strong><ul><li>PR tim će vas kontaktirati u vezi sa saopštenjem za javnost i datumom početka komunikacije.</li></ul></li><li><strong><u>Poslati informativni paket za vaš PR tim</u></strong><ul><li>Naš tim će vam poslati bedž Poslodavac Partner i ključne PR informacije.</li></ul></li><li><strong><u>Kreirati profil kompanije na veb lokaciji Poslodavac Partner</u></strong><ul><li>Promovišemo vašu organizaciju u regionalnoj poslovnoj zajednici.</li></ul></li><li><strong><u>Aktivirati Poslodavac Partner bedž</u></strong><ul><li>Bedž će biti prikazan na portalu Infostud.</li></ul></li><li><strong><u>Objaviti vesti u partnerskim medijima</u></strong><ul><li>Objavićemo PR članak na portalu Infostud.</li></ul></li><li><strong><u>Objaviti blog post i/ili intervju</u></strong><ul><li>Ispričaćemo priču o vašim izvanrednim praksama.</li></ul></li><li><strong><u>Podeliti vesti preko LinkedIn-a</u></strong><ul><li>Naši postovi dostižu između 2.000 i 7.000 korisnika po profilu i čitaju ih uglavnom stručnjaci na nivou C (40%).</li></ul></li></ul>
  <p>Poslodavci Partneri su najbolji ambasadori sertifikata, pa vas ohrabrujemo da podelite svoj uspeh!</p>
  `,
};

const officialConfirmation = {
  hr: `<div>
  <h2>Autorizacija</h2>
  <p>
    Klijent je obvezan proučiti ovo izvješće, te potvrditi da sve navedeno odražava trenutno stanje upravljanja ljudskim potencijalima u organizaciji.
  </p>
  <p>
    Ako Klijent utvrdi da postoji krivo interpretirana informacija, nedovoljno jasno ili netočno opisana procedura, izostavljena relevantna informacija, da se nakon pružanja informacija Timu za recertificiranje dogodila promjena unutar tvrtke vezana uz procese upravljanja ljudskim resursima te općenito informacija koju bi Klijent želio ispraviti, dužan je o navedenom obavijestiti Tim za certificiranje.
  </p>
  <p>
    Certifikatori potvrđuju da su bodovali opisane procese u skladu sa standardima definiranim Knjigom standarda.
  </p>
</div>
  `,
  en: `<div>
    <h2>Official confirmation</h2>
    <p>
      The Client is obliged to study the Final Report and, if it
      coincides with the actual situation, confirm its accuracy, ie
      confirm that all this reflects the current state of human
      resources management in the Client's company.
    </p>
    <p>
      If the Client finds that there is misinterpreted information,
      insufficiently clear or incorrectly described procedure, omitted
      relevant information, or that after providing information to the
      Certification Team there was a change within the company related
      to human resource management processes, the Client is obliged to
      inform the Certification Team.
    </p>
    <p>
      Members of the Certification Team confirm that Client's
      described processes were scored in accordance with the standards
      defined in the Book of Standards.
    </p>
  </div>
  `,
  sr: `<div>
  <h2>Autorizacija</h2>
  <p>
    Klijent je obvezan proučiti ovaj izveštaj, te potvrditi da sve navedeno odražava trenutno stanje upravljanja ljudskim potencijalima u organizaciji.
  </p>
  <p>
    Ako Klijent utvrdi da postoji krivo interpretirana informacija, nedovoljno jasno ili netačno opisana procedura, izostavljena relevantna informacija, da se nakon pružanja informacija Timu za sertifikaciju dogodila promena unutar kompanije vezana uz procese upravljanja ljudskim resursima, te generalno informacija koju bi Klijent želeo da ispravi, dužan je o navedenom obavestiti Tim za sertifikaciju.
  </p>
  <p>
    Sertifikatori potvrđuju da su bodovali opisane procese u skladu sa standardima definisanim Knjigom standarda.
  </p>
</div>
  `,
};

const audit = {
  about: {
    hr: `<h2>SELECTIO Grupa</h2><p>SELECTIO Grupa je najveća i vodeća grupacija za savjetovanje u upravljanju ljudskim potencijalima u Hrvatskoj. Već 20 godina djelujemo širom regije s timom profesionalaca koji imaju bogato iskustvo u raznim HR područjima te pružamo široku paletu usluga kao što su: <em>recruitment, executive search</em>, predselekcija, selekcija, psihologijsko testiranje te razne assessment usluge i usluge za ispitivanje organizacijske klime i angažiranosti.</p><p>Uz standardne usluge SELECTIO Grupe naši HR stručnjaci kontinuirano rade na razvoju edukativnih i razvojnih programa zbog čega nudimo brojne <em>soft skills</em> edukacije te programe kao što su SELECTIO HR Akademija, SELECTIO Leadership Akademija i Future Resilience. Zbog suradnje s vodećim organizacijama u mogućnosti smo prepoznati stvarne potrebe tržišta te ponuditi <em>tailor-made</em> rješenja koja prilagođavamo svakom klijentu.</p>`,
    en: `<h2>SELECTIO Group</h2><p>SELECTIO Group is the largest and leading human resources management consulting group in Croatia and the region. For 20 years, we have been operating throughout the region with a team of professionals who have extensive experience in various HR areas. We provide a wide range of services such as: recruitment, executive search, pre-selection, selection, psychological testing, assessment as well as measuring the organisational climate and engagement.</p>
    <p>In addition to SELECTIO Group services, our HR experts continuously work on educational and development programs, which is why we offer numerous soft skills training and programs such as SELECTIO HR Academy, SELECTIO Leadership Academy and Future Resilience. Due to cooperation with leading organisations, we are able to recognize the real needs of the market and offer tailor-made solutions that we adapt to each client.</p>`,
    sr: `<h2>SELECTIO Grupa</h2><p>SELECTIO Grupa je najveća i vodeća grupacija za savjetovanje u upravljanju ljudskim potencijalima u Hrvatskoj. Već 20 godina djelujemo širom regije s timom profesionalaca koji imaju bogato iskustvo u raznim HR područjima te pružamo široku paletu usluga kao što su: <em>recruitment, executive search</em>, predselekcija, selekcija, psihologijsko testiranje te razne assessment usluge i usluge za ispitivanje organizacijske klime i angažiranosti.</p><p>Uz standardne usluge SELECTIO Grupe naši HR stručnjaci kontinuirano rade na razvoju edukativnih i razvojnih programa zbog čega nudimo brojne <em>soft skills</em> edukacije te programe kao što su SELECTIO HR Akademija, SELECTIO Leadership Akademija i Future Resilience. Zbog suradnje s vodećim organizacijama u mogućnosti smo prepoznati stvarne potrebe tržišta te ponuditi <em>tailor-made</em> rješenja koja prilagođavamo svakom klijentu.</p>`,
  },
  hrAnalysis: {
    hr: `<h2>Analiza HR sustava iz dvije perspektive</h2>
    <p>Employer Partner metodologija donosi analizu HR sustava kroz employee lifecycle koji obuhvaća perspektivu poslodavca i perspektivu zaposlenika. Ovaj jedinstveni alat služi <strong>unaprijeđenju HR sustava</strong> i prilagođava se svakom klijentu održavajući <strong>jednak standard za sve.</strong></p>
    <p>Više od 100 kompanija godišnje koristi Employer Partner metodologiju koja im donosi:</p>
    <ul><li><strong>HR benchmark</strong></li><li>objektivnu <strong>procjenu HR sustava</strong></li><li>detaljne <strong>sugestije</strong> prilagođene vašem HR timu</li><li>sintezu i <strong>ključne zaključke</strong></li><li><strong>akcijski plan</strong> za napredak</li></ul>`,
    en: `<h2>Analysis of the HR system from two perspectives</h2>
    <p>The Employer Partner methodology provides an analysis HR system through the employee lifecycle, which includes the perspective of the employer and the perspective of the employee. This unique tool serves to<strong> improve the HR system</strong> and adapts to each client, maintaining<strong> the same standard for all.</strong></p>
    <p>More than 100 companies annually use the Employer Partner methodology, which brings them:</p>
    <ul><li><strong>HR benchmark</strong></li><li>objective <strong>assessment </strong>of the HR system</li><li>detailed <strong>suggestions </strong>tailored to your HR team</li><li>synthesis and<strong> key conclusions</strong></li><li><strong>action plan</strong> for improvement</li></ul>`,
    sr: `<h2>Analiza HR sustava iz dvije perspektive</h2>
    <p>Employer Partner metodologija donosi analizu HR sustava kroz employee lifecycle koji obuhvaća perspektivu poslodavca i perspektivu zaposlenika. Ovaj jedinstveni alat služi <strong>unaprijeđenju HR sustava</strong> i prilagođava se svakom klijentu održavajući <strong>jednak standard za sve.</strong></p>
    <p>Više od 100 kompanija godišnje koristi Employer Partner metodologiju koja im donosi:</p>
    <ul><li><strong>HR benchmark</strong></li><li>objektivnu <strong>procjenu HR sustava</strong></li><li>detaljne <strong>sugestije</strong> prilagođene vašem HR timu</li><li>sintezu i <strong>ključne zaključke</strong></li><li><strong>akcijski plan</strong> za napredak</li></ul>`,
  },
  structuralOverview: {
    hr: `<h2>Strukturirani pregled upravljanja ljudskim resursima</h2>
    <p>Svrha ovog izvještaja je pružiti strukturirani pregled upravljanja ljudskim potencijalima u vašoj organizaciji. Pomoću sugestija za dalji razvoj ljudskih resursa prilagođenih upravo vašoj organizaciji navigiramo vaš HR tim kako bi se, zajedno s cijelom organizacijom, razvijali u pravom smjeru.</p>`,
    en: `<h2>A structured overview of human resource management</h2>
    <p>The purpose of this report is to provide a structured overview of human resources management in your organisation. Using suggestions for the further development adapted to your organisation, we navigate your HR team so that they, together with the entire organisation, develop in the right direction.</p>`,
    sr: `<h2>Strukturirani pregled upravljanja ljudskim resursima</h2>
    <p>Svrha ovog izvještaja je pružiti strukturirani pregled upravljanja ljudskim potencijalima u vašoj organizaciji. Pomoću sugestija za dalji razvoj ljudskih resursa prilagođenih upravo vašoj organizaciji navigiramo vaš HR tim kako bi se, zajedno s cijelom organizacijom, razvijali u pravom smjeru.</p>`,
  },
  roles: {
    hr: `<h2>Uloge u implementaciji HR procesa</h2>
    <p>Kod implementacije predloženih sugestija za unapređenje HR procesa, važna je suradnja i uključenost svih strana: Uprave, HR funkcije i menadžera. U nastavku je prikazana podjela odgovornosti i ključnih uloga te način razvoja njihovih kapaciteta.</p>`,
    en: `<h2>Roles in HR process implementation</h2>
    <p>When implementing our suggestions for improving the HR process, cooperation and involvement of all stakeholders is of utmost importance: the Management Board, the HR Function and Managers. The division of responsibilities and key roles as well as the way of developing their capacities are presented below.</p>`,
    sr: `<h2>Uloge u implementaciji HR procesa</h2>
    <p>Kod implementacije predloženih sugestija za unapređenje HR procesa, važna je suradnja i uključenost svih strana: Uprave, HR funkcije i menadžera. U nastavku je prikazana podjela odgovornosti i ključnih uloga te način razvoja njihovih kapaciteta.</p>`,
  },
  rolesInfo: {
    hr: `<p>Moderno upravljanje ljudskim potencijalima predstavlja ključnu funkciju u organizaciji i objedinjuje, ne samo administrativne i operativne poslove, nego i strateško usmjeravanje procesa i zaposlenika u skladu s poslovnim ciljevima i strategijom razvoja. Prema najboljim praksama, u operativnom i strateškom dijelu HR funkcije treba biti zaposleno 1,4% ukupne radne snage.</p>
    <ul><li><h4>Strateška</h4>
    <p>organizacijski dizajn, dizajn modela kompetencija, strateško planiranje zapošljavanja, razvoja zaposlenika, nagrađivanja, nasljeđivanja i razvoja karijera, upravljanja i zadržavanja talenata</p></li><li><h4>Operativna</h4>
    <p>zapošljavanje, onboarding i offboarding, organizacija edukacija, koordinacija godišnjih razgovora i kalibracija, koordinacija wellbeing i recognition programa</p></li><li><h4>Administrativna</h4>
    <p>kadrovska administracija, registar zaposlenika, evidencija bolovanja, godišnjih odmora, radnog vremena, izračun i isplata plaća, zaštita na radu</p></li></ul>`,
    en: `<p>Modern human resources management represents a key function in the organisation. It combines administrative and operational tasks, with the focus on strategic quidance of processes and employees in accordance with business goals and development strategy. According to best practices, 1.4% of the total workforce should be employed in the operational and strategic part of the HR function.</p>
    <ul><li><h4>Strategic</h4>
    <p>organisational design, competency model design, strategic planning of recruitment, employee development, remuneration, succession and career development, talent management and retention</p></li><li><h4>Operational</h4>
    <p>recruitment, onboarding and offboarding, organisation of trainings, coordination of annual performance reviews and calibrations, coordination of well-being and recognition programs</p></li><li><h4>Administrative</h4>
    <p>personnel administration, employee registry, records of sick leave, vacations, working hours, calculation and payment of wages, occupational health and safety</p></li></ul>`,
    sr: `<p>Moderno upravljanje ljudskim potencijalima predstavlja ključnu funkciju u organizaciji i objedinjuje, ne samo administrativne i operativne poslove, nego i strateško usmjeravanje procesa i zaposlenika u skladu s poslovnim ciljevima i strategijom razvoja. Prema najboljim praksama, u operativnom i strateškom dijelu HR funkcije treba biti zaposleno 1,4% ukupne radne snage.</p>
    <ul><li><h4>Strateška</h4>
    <p>organizacijski dizajn, dizajn modela kompetencija, strateško planiranje zapošljavanja, razvoja zaposlenika, nagrađivanja, nasljeđivanja i razvoja karijera, upravljanja i zadržavanja talenata</p></li><li><h4>Operativna</h4>
    <p>zapošljavanje, onboarding i offboarding, organizacija edukacija, koordinacija godišnjih razgovora i kalibracija, koordinacija wellbeing i recognition programa</p></li><li><h4>Administrativna</h4>
    <p>kadrovska administracija, registar zaposlenika, evidencija bolovanja, godišnjih odmora, radnog vremena, izračun i isplata plaća, zaštita na radu</p></li></ul>`,
  },
  boardRole: {
    hr: `<h2>Uloga Uprave</h2>
    <ul><li>definira misiju, viziju i svrhu organizacije, postavlja ciljeve i strategiju njihovog ostvarenja na organizacijskom nivou</li><li>osigurava voditelju HR funkcije "mjesto za stolom" pri strateškom odlučivanju</li><li>osigurava budžet za razvoj kapaciteta upravljanja ljudskim resursima: dovoljan broj adekvatno educiranih HR zaposlenika i leadership edukacije za sve razine menadžmenta</li><li>osigurava budžet za informacijski sustav ( <em>HRIS</em>)</li><li>energizira, podržava i transparentno komunicira upravljanje promjenama</li><li>vodi primjerom</li></ul>`,
    en: `<h2>The Role of the Management Board</h2>
    <ul><li>leads creation of the mission, vision and purpose of the organisation, sets goals and strategies for their achievement at the organisational level</li><li>ensures that the Head of the HR function has a "seat at the table" during strategic decision-making</li><li>provides a budget for the development of human resources management capacity: a sufficient number of adequately educated HR employees and leadership training for the management team</li><li>ensures the budget for the information system (HRIS)</li><li>energizes, supports and transparently communicates change management</li><li>leads by example</li></ul>`,
    sr: `<h2>Uloga Uprave</h2>
    <ul><li>definira misiju, viziju i svrhu organizacije, postavlja ciljeve i strategiju njihovog ostvarenja na organizacijskom nivou</li><li>osigurava voditelju HR funkcije "mjesto za stolom" pri strateškom odlučivanju</li><li>osigurava budžet za razvoj kapaciteta upravljanja ljudskim resursima: dovoljan broj adekvatno educiranih HR zaposlenika i leadership edukacije za sve razine menadžmenta</li><li>osigurava budžet za informacijski sustav ( <em>HRIS</em>)</li><li>energizira, podržava i transparentno komunicira upravljanje promjenama</li><li>vodi primjerom</li></ul>`,
  },
  hrRole: {
    hr: `<h2>Uloga HR funkcije</h2>
    <ul><li>strateški partner businessu</li><li>razvija HR prakse sa svrhom ostvarenja ciljeva organizacije i dobrobiti zaposlenika</li><li>prati indikatore uspjeha i utjecaj HR praksi na organizaciju</li><li>prati zadovoljstvo zaposlenika i provedbu akcijskih planova</li><li>prilagođava sve ključne HR sustave u skladu s indikatorima uspjeha i okolišnim faktorima</li><li>mentorira i educira voditelje u implementaciji HR praksi</li><li>prilagođava people ciljeve voditelja i prati usklađenost s organizacijskim vrijednostima</li><li>osigurava leadership edukacije</li><li>upravlja kulturom organizacije</li></ul>`,
    en: `<h2>The Role of the HR Function</h2>
    <ul><li>strategic partner to the business</li><li>develops HR practices with the aim of achieving organisational goals and employee well-being</li><li>monitors indicators of success and impact of HR practices</li><li>monitors employee satisfaction and implementation of action plans</li><li>adapts all key HR systems in accordance with organisational values, success indicators and environmental factors</li><li>mentors and educates managers on the implementation of HR practices</li><li>adjusts people goals of the managers and monitors compliance with organisational values</li><li>provides leadership education</li><li>manages the organisational culture</li></ul>`,
    sr: `<h2>Uloga HR funkcije</h2>
    <ul><li>strateški partner businessu</li><li>razvija HR prakse sa svrhom ostvarenja ciljeva organizacije i dobrobiti zaposlenika</li><li>prati indikatore uspjeha i utjecaj HR praksi na organizaciju</li><li>prati zadovoljstvo zaposlenika i provedbu akcijskih planova</li><li>prilagođava sve ključne HR sustave u skladu s indikatorima uspjeha i okolišnim faktorima</li><li>mentorira i educira voditelje u implementaciji HR praksi</li><li>prilagođava people ciljeve voditelja i prati usklađenost s organizacijskim vrijednostima</li><li>osigurava leadership edukacije</li><li>upravlja kulturom organizacije</li></ul>`,
  },
  managerRole: {
    hr: `<h2>Uloga menadžera svih razina</h2>
    <ul><li>implementira HR prakse</li><li>prevodi organizacijske ciljeve i spušta do razine zaposlenika</li><li>osigurava da zaposlenici razumiju svoju ulogu u ostvarivanju ciljeva organizacije</li><li>osigurava motivaciju i angažiranost zaposlenika u obavljanju radnih zadataka</li><li>prati radni učinak zaposlenika i nagrađivanjem potiče ponašanja koja dovode do ostvarenja ciljeva</li><li>razvija zaposlenike u skladu s potrebama organizacije</li><li>osigurava zadovoljstvo zaposlenika, timsku koheziju i osjećaj pripadnosti poslodavcu</li></ul>`,
    en: `<h2>The Role of Managers</h2>
    <ul><li>implements HR practices</li><li>translates organisational goals to the employee level</li><li>ensures that employees understand their role in achieving the organisation's goals</li><li>monitors the work performance of employees and rewards them to encourage behaviors that lead to the achievement of organisation's goals</li><li>ensures employee motivation and engagement in performing work tasks</li><li>develops employees in accordance with the needs of the organisation</li><li>ensures employee satisfaction, team cohesion and a sense of belonging to the employer</li></ul>`,
    sr: `<h2>Uloga menadžera svih razina</h2>
    <ul><li>implementira HR prakse</li><li>prevodi organizacijske ciljeve i spušta do razine zaposlenika</li><li>osigurava da zaposlenici razumiju svoju ulogu u ostvarivanju ciljeva organizacije</li><li>osigurava motivaciju i angažiranost zaposlenika u obavljanju radnih zadataka</li><li>prati radni učinak zaposlenika i nagrađivanjem potiče ponašanja koja dovode do ostvarenja ciljeva</li><li>razvija zaposlenike u skladu s potrebama organizacije</li><li>osigurava zadovoljstvo zaposlenika, timsku koheziju i osjećaj pripadnosti poslodavcu</li></ul>`,
  },
  competenceCulture: {
    hr: `<h2>Kultura razvoja kompetencija</h2>
    <p>Za održivo upravljanje ljudskim potencijalima, važno je razviti kapacitete i kompetencije svih uključenih strana.</p>
    <p>Većina uspješnih organizacija prilikom definiranja strategije razvoja kompetencija primjenjuje princip „70-20-10“, pri čemu se 70% razvojnih aktivnosti odvija na radnom mjestu, 20% kroz interakciju s kolegama i 10% kroz edukacijske programe.</p>
    <p>U nastavku su navedene ključne teme za razvoj kapaciteta svih dionika u procesu upravljanja ljudskim potencijalima kao i mjere uspješnosti što je imperativ za daljnja unapređenja HR procesa.</p>`,
    en: `<h2>Culture of Competency Development</h2>
    <p>For the sustainable human resources management, it is important to develop the capacities and competences of all involved parties.</p><p>Most successful organisations apply the "70-20-10" principle when defining their competence development strategy, whereby 70% of development activities take place at the workplace, 20% through interaction with colleagues and 10% through educational programs.</p><p>The key topics for capacity development of all stakeholders in the human resources management process as well as performance measures are listed below,. This is imperative for further improvements iof HR processes.</p>`,
    sr: `<h2>Kultura razvoja kompetencija</h2>
    <p>Za održivo upravljanje ljudskim potencijalima, važno je razviti kapacitete i kompetencije svih uključenih strana.</p>
    <p>Većina uspješnih organizacija prilikom definiranja strategije razvoja kompetencija primjenjuje princip „70-20-10“, pri čemu se 70% razvojnih aktivnosti odvija na radnom mjestu, 20% kroz interakciju s kolegama i 10% kroz edukacijske programe.</p>
    <p>U nastavku su navedene ključne teme za razvoj kapaciteta svih dionika u procesu upravljanja ljudskim potencijalima kao i mjere uspješnosti što je imperativ za daljnja unapređenja HR procesa.</p>`,
  },
  measureHr: {
    hr: `<h2>Mjerenje uspješnosti HR funkcije</h2>
    <ul><li>ciljevi HR funkcije dio su individualnih ciljeva HR voditelja i članova tima (ostvarenje se prati kroz sustav upravljanja radnim učinkom)</li><li>praćenje HR operativnih mjera (zadovoljstvo HR procesom)</li><li>praćenje HR strateških mjera (zadovoljstvo ishodom/utjecajem HR procesa)</li><li>mjerenje zadovoljstva internih korisnika s HR funkcijom na godišnjoj razini</li><li>mjerenje zadovoljstva zaposlenika s ključnim HR procesima</li><li>mjerenje zadovoljstva klijenata/korisnika (indirektan pokazatelj)</li></ul>`,
    en: `<h2>Performance Measures for the HR Function</h2>
    <ul><li>the goals of the HR function are part of the individual goals of the HR manager and team members (achievement is monitored through the performance management system)</li><li>monitoring HR operational measures (process satisfaction)</li><li>monitoring HR strategic measures (satisfaction with the outcome/impact)</li><li>measuring the satisfaction of internal users with the HR function on an annual basis</li><li>measuring employee satisfaction with key HR processes</li><li>measuring client/user satisfaction (indirect indicator)</li></ul>`,
    sr: `<h2>Mjerenje uspješnosti HR funkcije</h2>
    <ul><li>ciljevi HR funkcije dio su individualnih ciljeva HR voditelja i članova tima (ostvarenje se prati kroz sustav upravljanja radnim učinkom)</li><li>praćenje HR operativnih mjera (zadovoljstvo HR procesom)</li><li>praćenje HR strateških mjera (zadovoljstvo ishodom/utjecajem HR procesa)</li><li>mjerenje zadovoljstva internih korisnika s HR funkcijom na godišnjoj razini</li><li>mjerenje zadovoljstva zaposlenika s ključnim HR procesima</li><li>mjerenje zadovoljstva klijenata/korisnika (indirektan pokazatelj)</li></ul>`,
  },
  measureBoard: {
    hr: `<h2>Mjerenje uspješnosti Uprave i menadžera</h2>
    <ul><li>ciljevi koji se odnose na upravljanje ljudskim resursima (people ciljevi) dio su individuanih ciljeva Uprave i voditelja svih razina (ostvarenje se prati kroz sustav upravljanja radnim učinkom)<ul><li>indeks prosječnog zadovoljstva i prosječne angažiranosti zaposlenika</li><li>ostali people ciljevi</li></ul></li><li>procjena rada se bazira, između ostalog, i na procjeni njihovih podređenih</li><li>mjerenje zadovoljstva zaposlenika s ključnim HR procesima na organizacijskom i timskom nivou</li><li>mjerenje zadovoljstva klijenata/korisnika (indirektan pokazatelj)</li></ul>`,
    en: `<h2>Performance Measurement for the Board and Managers</h2>
    <ul><li>goals related to human resource management (people goals) are part of the individual goals of the Board and managers of all levels (achievement is monitored through the performance management system)<ul><li>index of average satisfaction and average engagement of employees</li><li>other people goals</li></ul></li><li>performance evaluation is based, among other things, on the evaluation of their subordinates</li><li>measuring employee satisfaction with key HR processes at the organiational and team level</li><li>measuring client/user satisfaction (indirect indicator)</li></ul>`,
    sr: `<h2>Mjerenje uspješnosti Uprave i menadžera</h2>
    <ul><li>ciljevi koji se odnose na upravljanje ljudskim resursima (people ciljevi) dio su individuanih ciljeva Uprave i voditelja svih razina (ostvarenje se prati kroz sustav upravljanja radnim učinkom)<ul><li>indeks prosječnog zadovoljstva i prosječne angažiranosti zaposlenika</li><li>ostali people ciljevi</li></ul></li><li>procjena rada se bazira, između ostalog, i na procjeni njihovih podređenih</li><li>mjerenje zadovoljstva zaposlenika s ključnim HR procesima na organizacijskom i timskom nivou</li><li>mjerenje zadovoljstva klijenata/korisnika (indirektan pokazatelj)</li></ul>`,
  },
  developmentHr: {
    hr: `<h2>Razvoj kompetencija HR funkcije</h2>
    <ul><li>HR stručne kompetencije</li><li><em>business acumen</em></li><li>strateško planiranje</li><li>ključne kompetencije</li><li>kompetencije budućnosti</li><li>digitalne kompetencije</li><li>upravljanje ljudima i procesima: asertivnost, pregovaranje, facilitacija, medijacija</li><li>analiza i upravljanje podacima</li><li><em>finance for non-finance</em></li><li>projektno upravljanje</li><li>upravljanje promjenama</li><li>jačanje otpornosti na stres</li><li>poticanje raznoslikosti i inkluzije te sprečavanja diskriminacije</li><li>potrebe, mogućnosti i ograničenja osoba s invaliditetom</li></ul>`,
    en: `<h2>Competencies Development for the HR Function </h2>
    <ul><li>HR professional competences</li><li>business acumen</li><li>strategic planning</li><li>competencies of the future and digital competencies</li><li>managing people and processes: assertiveness, negotiation, facilitation, mediation, giving feedback</li><li>data analysis and data driven decisions</li><li>finance for non-finance</li><li>project management</li><li>change management</li><li>strengthening resilience to stress</li><li>encouraging diversity and inclusion and preventing discrimination</li><li>needs, possibilities and limitations of persons with disabilities</li></ul>`,
    sr: `<h2>Razvoj kompetencija HR funkcije</h2>
    <ul><li>HR stručne kompetencije</li><li><em>business acumen</em></li><li>strateško planiranje</li><li>ključne kompetencije</li><li>kompetencije budućnosti</li><li>digitalne kompetencije</li><li>upravljanje ljudima i procesima: asertivnost, pregovaranje, facilitacija, medijacija</li><li>analiza i upravljanje podacima</li><li><em>finance for non-finance</em></li><li>projektno upravljanje</li><li>upravljanje promjenama</li><li>jačanje otpornosti na stres</li><li>poticanje raznoslikosti i inkluzije te sprečavanja diskriminacije</li><li>potrebe, mogućnosti i ograničenja osoba s invaliditetom</li></ul>`,
  },
  developmentBoard: {
    hr: `<h2>Razvoj kompetencija Uprave i menadžera</h2>
    <ul><li><em>leadership</em> kompetencije</li><li><em>HR for non-HR</em></li><li>ključne kompetencije</li><li>usklađenost s organizacijskim vrijednostima</li><li>kompetencije budućnosti</li><li>digitalne kompetencije</li><li>upravljanje promjenama</li><li><em>finance for non-finance</em></li><li>upravljanje znanjem</li><li>jačanje otpornosti na stres</li><li>vještine mentoriranja</li><li>komunikacijske vještine i davanje povratne informacije</li><li><em>train-the-trainer</em></li><li>poticanje raznoslikosti i inkluzije te sprečavanja diskriminacije</li></ul>`,
    en: `<h2>Competencies Development for the Board and Managers</h2>
    <ul><li>leadership competencies</li><li>HR for non-HR</li><li>key competencies</li><li>compliance with organisational values</li><li>competences of the future</li><li>digital competences</li><li>change management</li><li>finance for non-finance</li><li>knowledge management</li><li>strengthening resilience to stress</li><li>mentoring skills</li><li>communication skills and giving feedback</li><li>train-the-trainer</li><li>encouraging diversity and inclusion and preventing discrimination</li></ul>`,
    sr: `<h2>Razvoj kompetencija Uprave i menadžera</h2>
    <ul><li><em>leadership</em> kompetencije</li><li><em>HR for non-HR</em></li><li>ključne kompetencije</li><li>usklađenost s organizacijskim vrijednostima</li><li>kompetencije budućnosti</li><li>digitalne kompetencije</li><li>upravljanje promjenama</li><li><em>finance for non-finance</em></li><li>upravljanje znanjem</li><li>jačanje otpornosti na stres</li><li>vještine mentoriranja</li><li>komunikacijske vještine i davanje povratne informacije</li><li><em>train-the-trainer</em></li><li>poticanje raznoslikosti i inkluzije te sprečavanja diskriminacije</li></ul>`,
  },
  actionPlanAndTimeline: {
    hr: `<h2>Akcijski plan i <em>timeline</em></h2><p>Kako bismo olakšali nastavak unaprijeđenja vašeg HR sustava, donosimo korake koje je potrebno provesti u prvoj godini implementacije sugestija.</p>`,
    en: `<h2>Action plan and timeline</h2><p>In order to facilitate the continued improvement of your HR system, we bring the steps that need to be implemented in the first year of implementation of the suggestions.</p>`,
    sr: `<h2>Akcijski plan i <em>timeline</em></h2><p>Kako bismo olakšali nastavak unaprijeđenja vašeg HR sustava, donosimo korake koje je potrebno provesti u prvoj godini implementacije sugestija.</p>`,
  },
  changeManagement: {
    hr: `<h2>Upravljanje promjenama</h2><p>Uvođenjem i razvojem HR procesa transformira se cijela organizacija. S obzirom da je riječ o procesu upravljanja promjenama, važno je da je sam razvoj HR sustava vođen načelima kvalitetnog upravljanja promjenama.</p>`,
    en: `<h2>Change management</h2><p>By introducing and developing the HR system, the whole organisation is transforming. Given that it is a change management process, it is important that the development of the HR system itself is guided by the principles of quality change management.</p>`,
    sr: `<h2>Upravljanje promjenama</h2><p>Uvođenjem i razvojem HR procesa transformira se cijela organizacija. S obzirom da je riječ o procesu upravljanja promjenama, važno je da je sam razvoj HR sustava vođen načelima kvalitetnog upravljanja promjenama.</p>`,
  },
  agilityHrProcess: {
    hr: `<h2>Zadržavanje agilnosti kroz razvoj HR procesa</h2><p>Jedan od imperativa je mobilizirati "agente promjene" i jasno utvrditi odgovornosti svakog dionika u procesu (HR funkcije, Uprave i menadžmenta). Organizacija treba, i u vrijeme promjena, zadržati agilnost, minimalizirati rizike i maksimizirati svoju produktivnost.</p><p>Tek se razvojem transparentnih i jasnih HR procesa te kompetentnih zaposlenika povećava i kapacitet organizacije da zadrži agilni pristup u poslovanju. Organizacije koje imaju jasno definirane najvažnije HR procese, i koje su ih implementirale na razini cijele organizacije, dokazano su agilnije u stvaranju dodatne vrijednosti za svoje zaposlenike, klijente i lokalnu zajednicu.</p>`,
    en: `<h2>Maintaining agility</h2><p>One of the imperatives is to mobilize "agents of change" and clearly determine the responsibilities of each stakeholder in the process (HR function, Board and management). The organisation should, even in times of change, maintain agility, minimize risks and maximize its productivity.</p><p>Only with the development of transparent and clear HR processes and competent employees does the organisation increase capacity to maintain an agile approach to business. Organizations that have clearly defined the most important HR processes, and have implemented them throughout the organisation, is proven to be more agile in creating additional value for their employees, clients and the local community.</p>`,
    sr: `<h2>Zadržavanje agilnosti kroz razvoj HR procesa</h2><p>Jedan od imperativa je mobilizirati "agente promjene" i jasno utvrditi odgovornosti svakog dionika u procesu (HR funkcije, Uprave i menadžmenta). Organizacija treba, i u vrijeme promjena, zadržati agilnost, minimalizirati rizike i maksimizirati svoju produktivnost.</p><p>Tek se razvojem transparentnih i jasnih HR procesa te kompetentnih zaposlenika povećava i kapacitet organizacije da zadrži agilni pristup u poslovanju. Organizacije koje imaju jasno definirane najvažnije HR procese, i koje su ih implementirale na razini cijele organizacije, dokazano su agilnije u stvaranju dodatne vrijednosti za svoje zaposlenike, klijente i lokalnu zajednicu.</p>`,
  },
  keyActivities: {
    hr: `<h2>Ključne potrebne aktivnosti za vašu organizaciju</h2><p>Nakon prezentiranja detaljnih sugestija za razvoj HR sustava, u nastavku se nalazi sažetak i sinteza najvažnijih aktivnosti za unaprijeđenje prilagođenih vašoj organizaciji.</p>`,
    en: `<h2>Key activities required for your organisation</h2><p>After presenting detailed suggestions for the development of your HR system, we present a summary and synthesis of the most important activities for improvement tailored to your organisation.</p>`,
    sr: `<h2>Ključne potrebne aktivnosti za vašu organizaciju</h2><p>Nakon prezentiranja detaljnih sugestija za razvoj HR sustava, u nastavku se nalazi sažetak i sinteza najvažnijih aktivnosti za unaprijeđenje prilagođenih vašoj organizaciji.</p>`,
  },
  risks: {
    hr: `<h2>Rizici statusa quo i nedovoljnog ulaganja u ključne HR procese</h2><p>U ključne HR procese važno je kontinuirano ulagati u skladu s ciljevima organizacije i okolišnim faktorima te imati na umu dugoročnu dobrobit zaposlenika.</p>`,
    en: `<h2>Risks of the status quo and insufficient investment in key HR processes</h2><p>It is important to continuously invest in key HR processes in accordance with the organisation's goals, desired organisational culture and environmental factors, and to keep in mind the long-term well-being of employees.</p>`,
    sr: `<h2>Rizici statusa quo i nedovoljnog ulaganja u ključne HR procese</h2><p>U ključne HR procese važno je kontinuirano ulagati u skladu s ciljevima organizacije i okolišnim faktorima te imati na umu dugoročnu dobrobit zaposlenika.</p>`,
  },
  risks0: {
    hr: `<h2>Selekcija</h2><ul><li>ako se nedovoljno ulaže u <em>employer branding </em>i regrutaciju kandidata:<ul><li>organizacija je nekonkurentna u privlačenju talenata</li><li>produžuje se vrijeme potrebno za pronalaženje adekvatnih zaposlenika</li></ul></li><li>ako se kasni sa zapošljavanjem potrebnog broja zaposlenika:<ul><li>raste opterećenost postojećih zaposlenika </li><li>rastu oportunitetni troškovi nezaposlene osobe</li><li>zaposlenicima i voditeljima se onemogućava fokusiranje na aktivnosti koje donose dodanu vrijednost: inovacije, razvoj kompetencija budućnosti, upravljanje promjenama</li></ul></li><li>ako se selekcija kandidata provodi nesustavno i nedovoljno kvalitetno, povećavaju se rizici:<ul><li>neuklapanja novozaposlene osobe u organizacijsku kulturu </li><li>rasta <em>early turnover-a </em>(odlazaka novih zaposlenika)</li><li>rasta oportunitetnih troškova zbog dodatnog ulaganja vremena HR tima, mentora i menadžera u ponovljeni selekcijski i <em>onboarding </em>proces </li></ul></li><li>ako se voditelje nesustavno educira o vođenju intervjua:<ul><li>raste mogućnost reputacijskih rizika</li><li>raste mogućnost negativnog iskustva kandidata</li></ul></li><li>ako se dugoročno planiranje zapošljavanja provodi nesustavno:<ul><li>organizacija je nespremna da pravovremeno odgovori na sadašnje i buduće poslovne potrebe </li><li>HR funkcija je preopterećena s procesima regrutiranja i selekcije kandidata, te stavlja ostale ključne HR procese u drugi plan</li></ul></li></ul>`,
    en: `<h2>Selection</h2><ul><li>if the investment in employer branding and candidate recruitment is insufficient:<ul><li>the organization is uncompetitive in attracting talent</li><li>the time required to find adequate employees is increasing</li></ul></li><li>if there is a delay in hiring the required number of employees:<ul><li>the workload of existing employees is increasing </li><li>the opportunity costs of an unemployed person is increasing</li><li>employees and managers are enabled to focus on activities that bring added value: innovation, development of future competencies, change management</li></ul></li><li>if candidate selection is carried out unsystematically and with insufficient quality, it increases the risk of:<ul><li>the newly hired person not fitting into the organisational culture</li><li>early turnover (departure of new employees)</li><li>growth in opportunity costs due to the additional investment of the HR team, mentors and managers in the repeated selection and onboarding process</li></ul></li><li>if managers are not systematically educated on conducting interviews:<ul><li>the possibility of reputational risks is increasing</li><li>the possibility of a negative candidate experience is increasing</li></ul></li><li>if long-term employment planning is not carried out systematically:<ul><li>the organisation is not ready to respond to current and future business needs in a timely manner</li><li>the HR function is overloaded with recruitment and candidate selection processes, and puts other key HR processes on hold</li></ul></li></ul>`,
    sr: ``,
  },
  risks1: {
    hr: `<h2>Razvoj kompetencija</h2><ul><li>ako je model kompetencija (zajedničke, specifične i <em>leadership </em>kompetencije) nedovoljno razrađen, ne postoji zajedničko razumijevanje:<ul><li>koja su ponašanja poželjna i reflektiraju organizacijske vrijednosti i željenu organizacijsku kulturu </li><li>koje su kompetencije potrebne za ostvarivanje poslovne strategije </li></ul></li><li>ako je povezanost modela kompetencija s drugim ključnim HR procesima nesustavna:<ul><li>zapošljavaju se kandidati koji ne posjeduju adekvatne kompetencije za radno mjesto i/ili se ne uklapaju u organizacijsku kulturu</li><li>zaposlenici se ne procjenjuju standardizirano prema dimenziji KAKO se radi te se ne mogu prema istim kriterijima ni nagrađivati, razvijati i dobivati strukturiranu povratnu informaciju </li><li>onemogućeno je sustavno prepoznavanje nasljednika i talenata </li></ul></li><li>ako su kompetencije budućnosti (npr. digitalna transformacije, agilna transformacija, robotizacija) nedovoljno definirane, ne postoji razumijevanje: <ul><li>koje su kompetencije potrebne za realizaciju dugoročne strategije, provedbu transformacijskih inicijativa i prilagodbu okolišnim faktorima za osiguravanje održivosti poslovanja</li><li>koliki je jaz između postojećih i željenih kompetencija potrebnih za realizaciju dugoročne strategije poslovanja</li></ul></li><li>ako je povezanost kompetencija budućnosti s ključnim HR procesima nesustavna: <ul><li>zapošljavaju se kandidati koji ne posjeduju potencijal za razvijanje kompetencija budućnosti i zaposlenici nisu pripremljeni za preuzimanje drugih odgovornosti </li></ul></li></ul>`,
    en: `<h2>Building Competencies</h2><ul><li>if the competency model (key, specific and leadership competencies) is insufficiently developed, there is no common understanding:<ul><li>which behaviours are desirable and reflect organisational values and desired organisational culture</li><li>what competencies are needed to implement the business strategy</li></ul></li><li>if the connection of the competency model with other key HR processes is not systematic:<ul><li>candidates are hired who do not have adequate competencies for the position and/or do not fit into the organizational culture</li><li>employees are not assessed in a standardised manner according to the dimension HOW the work is done, and they cannot be rewarded and developed and cannot receive structured feedback according to the same criteria</li><li>the systematic recognition of successors and talents is prevented</li></ul></li><li>if the competencies of the future (e.g. digital transformations, agile transformation, robotization) are insufficiently defined, there is no understanding:<ul><li>which competencies are needed for the realisation of a long-term strategy, the implementation of transformational initiatives and adaptation to environmental factors to ensure business sustainability</li></ul></li><li>if competencies of the future are not systematically connected to the key HR processes:<ul><li>candidates are hired who do not have the potential to develop competencies of the future and employees are not prepared to take on other responsibilities</li><li>what is the gap between the existing and desired competencies needed for the realisation of a long-term business strategy</li></ul></li></ul>`,
    sr: ``,
  },
  risks2: {
    hr: `<h2>Leadership</h2><ul><li>ako je procjenjivanje menadžera prema <em>leadership </em>kompetencijama, organizacijskim vrijednostima te od strane svojih podređenih nesustavno:<ul><li>menadžeri ne dobivaju povratnu informaciju koja ponašanja su poželjna i nepoželjna </li><li>menadžeri ne mogu voditi zaposlenike svojim primjerom</li></ul></li><li>ako je ulaganje u razvoj vještina menadžera za upravljanja ljudima i primjenu ključnih HR procesa nesustavno:<ul><li>HR procesi se ne provode standardizirano kroz organizaciju i ne odražavaju potrebnu razinu kvalitete</li><li>razumijevanje poslovnih ciljeva i njihovo kaskadiranje do razine zaposlenika te komuniciranje zaposlenicima njihove uloge u ostvarivanju ciljeva organizacije je nije standardizirano</li><li>motiviranje zaposlenika te osiguravanje zadovoljstva i angažiranosti zaposlenika, timske kohezije i osjećaja pripadnosti poslodavcu nije standardizirano</li><li>upravljanje zaposlenicima u skladu sa željenom organizacijskom kulturom i vrijednostima nije standardizirano</li></ul></li><li>ako je definiranje individualnih ciljeva menadžera koji su vezani uz upravljanje zaposlenicima nesustavno:<ul><li>menadžeri nemaju izbalansirane ciljeve te se fokusiraju na financijske ciljeve, a ne i na dugoročnu dobrobit i održivu produktivnost zaposlenika</li></ul></li></ul>`,
    en: `<h2>Leadership</h2><ul><li>if the managers are not systematically assessed according to leadership competencies, organizational values and by their subordinates:<ul><li>they do not receive feedback which behaviours are desirable and which behaviours are undesirable</li><li>managers can not lead employees by example</li></ul></li><li>if the development of the managers' leadership skills and the skills to apply key HR processes is not systematic:<ul><li>HR processes are not implemented in a standardised way throughout the organisation and do not reflect the required level of quality</li><li>understanding business goals and cascading goals down to the employee level is not standardised, and neither is employees' understanding of their role in achieving the organisation&#39;s goals</li><li>motivating employees and ensuring employee satisfaction and engagement, team cohesion and a sense of belonging to the employer is not standardised</li><li>managing employees in accordance with the desired organizational culture and values is not standardised</li></ul></li><li>if defining managers' individual goals related to the employee management is not systematic:<ul><li>managers do not have balanced business goals and they do not focus on the long-term welfare and sustainable productivity of employees</li></ul></li></ul>`,
    sr: ``,
  },
  risks3: {
    hr: `<h2>Radna uspješnost</h2><ul><li>ako elementi sustava procjenjivanja nisu u dovoljnoj mjeri definirani:<ul><li>ne postoji zajedničko razumijevanje što se ocjenjuje i koja je težina pojedinih elemenata procjene (npr. ostvarenje individualnih ciljeva vs. ocjena po sustavu kompetencija) te na koji se način definira konačna ocjena </li><li>ocjenjivanje ovisi o subjektivnom dojmu menadžera što onemogućuje transparento i standardizirano povezivanje individualnog radnog učinka s razvojem, nagrađivanjem i napredovanjem</li></ul></li><li>ako sustav procjenjivanja nije implementiran na cijelu organizaciju, ne provodi se kalibracija ocjena i sustav procjenjivanja nije dvosmjeran: <ul><li>ne potiču se poželjna ponašanja kroz cijelu organizaciju što utječe na organizacijsku kulturu i u konačnici na zadovoljstvo klijenata (<em>customer satisfaction</em>)</li><li>ne kalibriraju se konačne ocjene na organizacijskoj razini što onemogućuje standardiziranost sustava procjenjivanja i posljedično sustava napredovanja i razvoja</li><li>zaposlenici nisu u mogućnosti sami sebe procjenjivati i davati povratnu informaciju o pruženoj podršci od strane njihovih menadžera za izvršavanje zadanih ciljeva što onemogućuje pravednost sustava </li></ul></li><li>ako kriteriji procjene radne uspješnosti nisu jasno usklađeni s poslovnom strategijom i ako se ciljevi ne evaluiraju više puta godišnje:<ul><li>poslovni ciljevi nisu kaskadirani na sva radna mjesta što onemogućuje stratešku usmjerenost i efikasno ostvarivanje poslovnih planova</li><li>ne potiče se agilno upravljanje ciljevima</li></ul></li></ul>`,
    en: `<h2>Performance</h2><ul><li>if the elements of the performance management / development system are not sufficiently defined:<ul><li>there is no common understanding what is being assessed and what is the weight of  certain assessment elements (e.g. the realisation of individual goals vs. evaluation according to the competence system) and how the final grade is defined</li><li>evaluation depends on the subjective impression of the manager, which prevents a transparent and standardized connection of individual performance with development, compensation and promotion</li></ul></li><li>if the evaluation system is not implemented throughout the organisation, the calibration of grades is not carried out and the evaluation system is not a two-way system:<ul><li>desirable behaviors are not encouraged throughout the organisation, which affects organisational culture and ultimately customer satisfaction</li><li>the final grades are not calibrated at the organisational level, which makes it impossible to standardise the performance evaluation system and, consequently, the promotion, compensation and development system</li><li>employees are not able to evaluate themselves or give feedback on the support provided by their managers for the execution of the set goals, which prevents the fairness of the system</li></ul></li><li>if the work performance evaluation criteria are not clearly aligned with the business strategy and if the goals are not evaluated several times a year:<ul><li>business goals are not cascaded to all levels, which makes strategic orientation and efficient implementation of business plans impossible</li><li>agile goal management is not encouraged</li></ul></li></ul>`,
    sr: ``,
  },
  risks4: {
    hr: `<h2>Uključenost i zadovoljstvo</h2><ul><li>ako se ispitivanje klime, zadovoljstva i angažiranosti zaposlenika ne provodi sustavno, nije moguće utvrditi:<ul><li>koliko je prosječno zadovoljstvo i angažiranost zaposlenika te koliko se razlikuje zadovoljstvo i angažiranost zaposlenika po odjelima i timovima</li><li>koje su dimenzije organizacijske klime najviše povezane s angažiranosti zaposlenika</li><li>kakav je trend kretanja indeksa angažiranosti iz godine u godinu</li></ul></li><li>ako rezultati zadnjeg ispitivanja klime, zadovoljstva i angažiranosti nisu sustavno komunicirani zaposlenicima te ako na temelju rezultata nije formiran i proveden akcijski plan, doći će do:<ul><li>pada povjerenja od strane zaposlenika prema menadžmentu i HR funkciji zbog netransparentnosti procesa</li><li>pada motivacije zaposlenika za ispunjavanje anketa i davanje iskrenih odgovora</li><li>neispunjavanja očekivanja zaposlenika za promjenom i negativnog utjecaja na organizacijsku klimu i kulturu </li></ul></li><li>ako je trend kretanja indeksa angažiranosti u odnosu na prethodno provedeno istraživanje silazan:<ul><li>potrebno je analizirati razloge silaznog trenda te što prije definirati i realizirati akcijske planove i na organizacijskom i na timskom nivou u svrhu održavanja angažiranosti zaposlenika i retencije talenata</li></ul></li></ul>`,
    en: `<h2>Engagement & Satisfaction</h2><ul><li>if the organisational climate, satisfaction and engagement survey is not carried out systematically, it is not possible to determine:<ul><li>what is the average satisfaction and engagement index on the organisational level and how much employee satisfaction and engagement differ by department and team</li><li>which dimensions of the organizational climate are connected to employee engagement</li><li>what is the trend of the engagement index from year to year</li></ul></li><li>if the results of the last organisational climate, satisfaction and engagement survey were not systematically communicated to employees, and if an action plan was not formed and implemented, the following will occur:<ul><li>a drop in employee trust towards management and the HR function due to the non-transparency of the process</li><li>the employee's motivation to fill out surveys and give honest answers decreases</li><li>failure to meet employee expectations for change and negative impact on the organizational climate and culture</li></ul></li><li>if the trend of the engagement index in relation to the previous survey is descending:<ul><li>it is necessary to analyse the reasons for the descending trend and to define and implement action plans as soon as possible both at the organizational and team level in order to maintain employee engagement and talent retention</li></ul></li></ul>`,
    sr: ``,
  },
  risks5: {
    hr: `<h2>Karijera i nasljeđivanje</h2><ul><li>ako elementi sustava napredovanja nisu u dovoljnoj mjeri definirani te ako sustav napredovanja i razvoja karijere nije povezan s procjenom radne uspješnosti za sve grupe zaposlenika:<ul><li>organizacija nema pogled prema budućnosti, ne prepoznaje potencijal zaposlenika te ne identificira sustavno nasljednike i talenate</li><li>određena skupina zaposlenika zapostavljena je u razvoju karijera te se ne iskorištava puni potencijal za krosfunkcijsko napredovanje</li></ul></li><li>ako nisu definirane karijerne razine s razinama očekivanih kompetencija:<ul><li>zaposlenici i menadžeri nemaju transparentne informacije koje su razine kompetencija potrebne na određenim pozicijama te se ne mogu standardizirano procijeniti i planirati svoj karijerni put </li></ul></li><li>ako se nesustavno upravlja karijerom zaposlenika:<ul><li>postoji visoka mogućnost pada lojalnosti i angažiranosti te zatim i odljeva talenata, nasljednika i ključnih zaposlenika zbog nedosljednosti u promocijama, predugog zadržavanja na istim pozicijama i nerazvijanja potencijala te neispunjavanja profesionalnih ciljeva unutar organizacije</li></ul></li><li>ako ne postoji strategija popunjavanja ključnih pozicija:<ul><li>ugrožen je kontinuitet poslovanja jer organizacija nema spremne nasljednike za ključne voditeljske i ključne specijalističke pozicije</li><li>eventualnim odlaskom osobe s ključne pozicije, bez prijenosa znanja na nasljednika, odlazi i specifično znanje i iskustvo potrebno za održivost poslovanja</li></ul></li></ul>`,
    en: `<h2>Career & Succession</h2><ul><li>if the elements of the promotion system are not sufficiently defined and if the promotion and career development system is not connected to the performance management / development system for all groups of employees:<ul><li>the organisation does not have the future perspective, it does not recognise the potential of employees, and does not systematically identify successors and talents</li><li>a certain group of employees is neglected in career development and the full potential for cross-functional advancement is not used</li></ul></li><li>if career levels and levels of expected competencies are not defined:<ul><li>employees and managers do not have transparent information about the level of competence required in certain positions and cannot plan their career path, employees cannot be assessed in a standardized manner</li></ul></li><li>if the employee's career is not systematically managed:<ul><li>there is a high possibility of a drop in loyalty and engagement, as well as a regretted turnover of talents, successors and key employees due to inconsistency in promotions, staying in the same positions for too long, not developing potential and not fulfilling professional goals within the organisation</li></ul></li><li>if there is no strategy for filling key positions:<ul><li>business continuity is threatened because the organization does not have ready successors for key management and key specialist positions</li><li>during the possible departure of a key expert, without the organised transfer of knowledge to the successor, the specific knowledge and experience necessary for the sustainability of the business is lost</li></ul></li></ul>`,
    sr: ``,
  },
  risks6: {
    hr: `<h2>Nagrađivanje</h2><ul><li>ako sustav nagrađivanja (povišice i bonusi / nagrade) nije direktno povezan s procjenom radne uspješnosti i individualnim doprinosom te ako sustav nagrađivanja nije implementiran na cijelu organizaciju:<ul><li>ne postoji zajedničko razumijevanje što se nagrađuje (npr. realizacija individualnih ciljeva vs. ocjena po sustavu kompetencija), i koji je udio organizacijskih / odjelnih / individualnih ciljeva ovisno o utjecaju na ostvarenje organizacijskih rezultata</li><li>nagrađivanje ovisi o subjektivnom dojmu menadžera što onemogućuje transparentno, pravedno i standardizirano povezivanje individualnog radnog učinka s nagrađivanjem</li><li>posljedično dolazi do pada zadovoljstva i angažiranosti zaposlenika</li></ul></li><li>ako se svi zaposlenici koji su ostvarili iznadprosječan radni učinak (po rezultatu / kvaliteti) sustavno ne nagrađuju:<ul><li>postoji velika mogućnost odljeva <em>top performera</em></li></ul></li><li>ako sustav plaća nije u dovoljnoj mjeri definiran:<ul><li>sustav nagrađivanja i sustav napredovanja nije dovoljno transparentan i pravedan već ovisi o subjektivnom dojmu voditelja</li></ul></li><li>ako se istraživanja plaća na tržištu rada ne provode sustavno:<ul><li>ne mogu se utvrditi diskrepancije u plaćama te se ne mogu kvalitetno provoditi korekcije plaća u skladu s okolišnim faktorima što direktno utječe na retenciju zaposlenika</li></ul></li></ul>`,
    en: `<h2>Compensation</h2><ul><li>if the reward system (raises and bonuses / awards) is not directly related to the performance management / development and the individual contribution and if the reward system is not implemented on the entire organization:<ul><li>there is no common understanding of what is rewarded (e.g. realization of individual goals vs. evaluation according to the competence system), and what is the weight of organizational / departmental / individual goals depending on the complexity of the position (or the individual impact on the achievement of organizational results)</li><li>rewarding depends on the subjective impression of the manager which makes it impossible for individual work performance to be connected with rewarding in a transparent, fair and standardized way</li><li>it leads to decrease in employee satisfaction and engagement</li></ul></li><li>if all employees who achieved above-average work performance (by result / quality) are not systematically rewarded:<ul><li>there is a great possibility of the regretted turnover of top performers</li></ul></li><li>if the salary system is not sufficiently defined:<ul><li>the reward system and the promotion system are not sufficiently transparent and fair, but depend on the subjective impression of the manager</li></ul></li><li>if salary surveys according to the market are not systematically conducted:<ul><li>salary discrepancies cannot be determined and salary corrections cannot be carried out in a quality manner in accordance with labour market and market trends, which directly affects employee retention</li></ul></li></ul>`,
    sr: ``,
  },
  risks7: {
    hr: `<h2>Dobrobit</h2><ul><li>ako se inicijative za brigu za zdravlje zaposlenika (i njihovih članova obitelji) ne provode sustavno:<ul><li>organizacija nema fokus na dugoročnu dobrobit i produktivnost zaposlenika, odnosno  nema pogled u budućnost i ne vodi brigu o održivom poslovanju, dugoročnoj konkurentnosti i željenoj organizacijskoj kulturi</li><li>organizacija ne "živi" svoje vrijednosti i ne pokazuje primjerom kakav utjecaj želi ostvariti u svojoj zajednici   </li></ul></li><li>ako organizacija sustavno ne prati zdravlje zaposlenika i zadovoljstvo timskom kohezijom i međuljudskim odnosima te programima dobrobiti:<ul><li>nije moguće utvrditi objektivno stanje fizičkog i mentalnog zdravlja zaposlenika na nivou cijele organizacije i različitih grupa zaposlenika (po radnim mjestima, dobnim skupinama, itd.)</li><li>nije moguće ni dizajnirati i implementirati kvalitetne inicijative koje će donijeti najveću korist za zaposlenike i organizaciju</li><li>nije moguće proaktivno djelovati unapređivanjem postojećih inicijativa i uvođenjem novih u skladu s okolišnim faktorima i stanjem zdravlja zaposlenika (inicijative za brigu za zdravlje, jačanje psihološke otpornosti (<em>resilience</em>) i sprečavanje <em>burnouta te </em>inicijative iz područja društvene odgovornosti koje uključuju zaposlenike)</li></ul></li></ul>`,
    en: `<h2>Wellbeing</h2><ul><li>if health care initiatives for employees (and their family members) are not implemented systematically:<ul><li>the organisation does not have a focus on the long-term wellbeing and productivity of employees, that is, it does not have future perspective and does not sufficiently care of sustainable business, long-term competitiveness and the desired organisational culture</li><li>the organisation does not "live" its values and will not achieve desired impact in the community</li></ul></li><li>if the organization does not systematically monitor employee health and satisfaction with team cohesion, interpersonal relations and welfare programs:<ul><li>it is not possible to determine the objective state of physical and mental health of employees at the organisational level and for different groups of employees (by workplace, age group, etc.)</li><li>it is not possible to design and implement quality initiatives that will bring the greatest benefit for employees and the organization</li><li>it is not possible to act proactively by improving existing initiatives and introducing new ones in accordance with environmental factors and the health status of employees (health care initiatives, initiatives to strengthen psychological resilience, to prevent burnout, as well as initiatives in the field of corporate social responsibility that include employees)</li></ul></li></ul>`,
    sr: ``,
  },
  risks8: {
    hr: `<h2>HR kapacitet</h2><ul><li>ako u organizaciji nije zaposlen dovoljan broj HR stručnjaka:<ul><li>HR funkcija nema potrebne resurse da proaktivno razvija HR prakse sa svrhom ostvarenja organizacijskih ciljeva</li><li>u fokusu su prvenstveno administrativni te operativni zadaci, dok za strateške teme ne postoje dovoljni resursi, bez obzira na dobru volju i želju HR tima</li></ul></li><li>ako HR funkcija nije ispravno pozicioinirana u hijerarhiji organizacije, nije u mogućnosti:<ul><li>utjecati na odluke koje se odnose na ljude ravnopravno s drugim funkcijama </li><li>voditi brigu da je upravljanje ljudima uvijek jedan od strateških prioriteta</li><li>imati autoritet da Upravi i voditeljima prilagođava <em>people </em>ciljeve i prati usklađenost s organizacijskim vrijednostima</li></ul></li><li>ako HR funkcija ne upravlja sa svim ključnim HR procesima:<ul><li>ne može kvalitetno upravljati organizacijskom kulturom i mjeriti odražavaju li svi HR procesi željene organizacijske vrijednosti</li></ul></li><li>ako se ne koristi informatički sustav za upravljanje ljudskim resursima:<ul><li>digitalno upravljanje podacima u svrhu jačanja kapaciteta za <em>data driven </em>odluke nije omogućeno</li><li>efikasnost HR odjela te cijele organizacije u provođenju HR procesa je na niskom nivou</li></ul></li><li>ako nije osiguran razvoj stručnih kompetencija zaposlenika HR odjela:<ul><li>HR funkcija nije upoznata s globalnim trendovima, nema <em>business acumen</em> i nedostaju joj potrebna znanja za upravljanje promjenama</li></ul></li></ul>`,
    en: `<h2>HR Capacity</h2><ul><li>if the organisation does not have a sufficient number of HR experts employed:<ul><li>the HR function does not have the necessary resources to proactively develop HR practices with the aim of achieving organisational goals</li><li>the focus is primarily on administrative and operational tasks, and, regardless of the goodwill and desire of
    the HR team, there is no possibility to engage in strategic topics</li></ul></li><li>if the HR function is not well-positioned in the organisational hierarchy, it is not able to:<ul><li>influence decisions related to people equally with other functions</li><li>make sure that the people management is always one of the strategic priorities</li><li>have the authority to adapt people goals for the Management Board and managers and monitor compliance with organizational values</li></ul></li><li>if the HR function does not manage all key HR processes:<ul><li>it cannot manage the organisational culture well and measure whether all HR processes reflect the desired organisational values</li></ul></li><li>if no IT system for human resources management is used:<ul><li>digital data management for strengthening capacity for data-driven decisions is enabled</li><li>the efficiency of the HR department and the entire organisation in implementing HR processes is at a low level</li></ul></li><li>if the professional competence development of HR department employees is not ensured:<ul><li>the HR function is not familiar with global trends, lacks business acumen and the necessary knowledge to manage change</li></ul></li></ul>`,
    sr: ``,
  },
  risks9: {
    hr: `<h2>HR strateško savjetovanje</h2><ul><li>ako voditelj HR funkcije ne sudjeluje sustavno u poslovnom odlučivanju i strateškom planiranju te provođenju promjena vezanih za poslovanje, HR funkcija nije u mogućnosti:<ul><li>postati strateški partner <em>businessu </em>u stvaranju dodatne vrijednosti</li><li>imati sve potrebne informacije vezane uz poslovnu strategiju u svrhu proaktivnog djelovanja i upravljanja rizicima </li><li>prepoznati i razumjeti <em>stakeholdere </em>i njihove perspektive</li></ul></li><li>ako strategija HR funkcije nije dokumentirana i povezana s poslovnim ciljevima:<ul><li>ne postoji fokus na one HR teme koje će imati najveći utjecaj na organizaciju te doprinijeti uspješnoj provedbi postavljenih poslovnih ciljeva u svrhu osiguranja kontinuiteta poslovanja i organizacijskog rasta</li><li>ne postoji kontinuirani fokus na dugoročnu dobrobit i produktivnosti zaposlenika</li></ul></li><li>ako se ostvarenje strategije HR funkcije ne provodi sustavno te ako ne postoji formalno praćenje HR procesa, uključujući i operativne i strateške mjere:<ul><li>nije moguće procijeniti ni kvalitetu samih HR procesa, utjecaj HR praksi i jesu li procesi i inicijative dovele do željenih ishoda </li><li>nije moguće napraviti kvalitetne nadogradnje HR procesa u skladu s potrebama organizacije, radnih timova i zaposlenika te u skladu s okolišnim faktorima</li></ul></li></ul>`,
    en: `<h2>Strategic Advisory</h2><ul><li>if the HR Manager does not systematically participate in business decision-making, strategic planning and the implementation of business-related changes, the HR function is not able to:<ul><li>become a strategic business partner in creating additional value</li><li>have all the necessary information related to business strategy for the purpose of proactive action and risk management</li><li>recognize and understand stakeholders and their perspectives</li></ul></li><li>if the strategy of the HR function is not documented and linked to business goals:<ul><li>there is no focus on those HR topics that will have the greatest impact on the organisation and that will contribute to the successful implementation of business goals in order to ensure business continuity and organisational growth</li><li>there is no continuous focus on the long-term wellbeing and productivity of employees</li></ul></li><li>if the realization of the strategy of the HR function is not carried out systematically and if there is no formal monitoring of the HR process, including operational and strategic measures:<ul><li>it is not possible to assess the quality of the HR processes, nor the impact of HR practices, and whether the processes and initiatives led to the desired outcomes</li><li>it is not even possible to make quality upgrades of the HR process in accordance with the needs of the organisation, work teams and employees and in accordance with environmental factors</li></ul></li></ul>`,
    sr: ``,
  },
};

const abAbout = {
  hr: `<div style="left: 45px; position: relative; top: 54px; width: 280px;">
  <h2>Što je <span style="color:#1f72ba">Employer Partner</span> Certifikat</h2>
  <p style="font-size: 0.8rem;"><strong>posjetite <a href="https://www.employerparner.eu/hr" style="text-decoration:underline">www.employerparner.eu/hr</a></strong></p>
  <p>Grupa SELECTIO, najveća je i vodeća grupacija za savjetovanje ljudskim potencijalima u Hrvatskoj, već 20 godina dodjeljuje certifikat Employer Partner tvrtkama koje pokazuju visoke standarde i rast u upravljanju ljudskim potencijalima.</p>
</div>
  `,
  en: `<div style="left: 45px; position: relative; top: 54px; width: 280px;">
  <h2>What is the <span style="color:#1f72ba">Employer Partner</span> certificate?</h2>
  <p style="font-size: 0.8rem;"><strong>visit <a href="https://www.employerparner.eu">www.employerparner.eu</a></strong></p>
  <p>For almost 20 years, SELECTIO Group, the largest human resources consulting group in Croatia, has been awarding the Employer Partner certificate to companies that demonstrate high standards and growth in human resources management.</p>
</div>
  `,
  sr: `<div style="left: 45px; position: relative; top: 54px; width: 280px;">
  <h2>Što je <span style="color:#1f72ba">Employer Partner</span> Certifikat</h2>
  <p style="font-size: 0.8rem;"><strong>posjetite <a href="https://www.employerparner.eu/hr">www.employerparner.eu/hr</a></strong></p>
  <p>Grupa SELECTIO, najveća je i vodeća grupacija za savjetovanje ljudskim potencijalima u Hrvatskoj, već 20 godina dodjeljuje certifikat Employer Partner tvrtkama koje pokazuju visoke standarde i rast u upravljanju ljudskim potencijalima.</p>
</div>
  `,
};

const abAboutCerifiers = {
  hr: `<div style="left: 45px; position: relative; top: 54px; width: 325px;">
    <h2><span style="color:#1f72ba">100 kompanija</span> prijavljuje se za certifikat <span style="color:#1f72ba">svake godine</span></h2>
    <p style="font-size: 0.8rem;"><strong>posjetite <a href="https://employerpartner.eu/hr/nositelji-certifikata/" style="text-decoration:underline">listu certificiranih poslodavaca</a></strong></p>
    <p>Promičemo najbolja radna mjesta izgrađena na vrhunskom upravljanju u ljudskim potencijalima. Do visoke kvalitete držimo i sami zbog čega smo okupili <strong>50 HR stručnjaka</strong> koji certificiraju <strong>više od 100 organizacija godišnje</strong>, a dodatne napore ulažemo u sve naprednije digitalne procese.</p>
  </div>
    `,
  en: `<div style="left: 45px; position: relative; top: 54px; width: 325px;">
    <h2><span style="color:#1f72ba">100 companies</span> apply for the certificate <span style="color:#1f72ba">every year</span></h2>
    <p style="font-size: 0.8rem;"><strong>posjetite <a href="https://employerpartner.eu/awarded-employers/" style="text-decoration:underline">www.employerparner.eu/hr</a></strong></p>
    <p>We promote the best workplaces built on top management in human resources, motivating companies for continuous growth and development. We gathered <strong>50 HR experts</strong> who have been certifying companies in <strong>more than 100 industries.</strong></p>
  </div>
    `,
  sr: `<div style="left: 45px; position: relative; top: 54px; width: 325px;">
    <h2><span style="color:#1f72ba">100 kompanija</span> prijavljuje se za certifikat <span style="color:#1f72ba">svake godine</span></h2>
    <p style="font-size: 0.8rem;"><strong>posjetite <a href="https://employerpartner.eu/hr/nositelji-certifikata/" style="text-decoration:underline">listu certificiranih poslodavaca</a></strong></p>
    <p>Promičemo najbolja radna mjesta izgrađena na vrhunskom upravljanju u ljudskim potencijalima. Do visoke kvalitete držimo i sami zbog čega smo okupili <strong>50 HR stručnjaka</strong> koji certificiraju <strong>više od 100 organizacija godišnje</strong>, a dodatne napore ulažemo u sve naprednije digitalne procese.</p>
  </div>
    `,
};

const abMethodology = {
  hr: `<div style="left: 45px; position: relative; top: 35px; width: 325px;">
    <h2>Employer Partner <span style="color:#1f72ba">metodologija</span></h2>
    <p>HR sustav promatramo očima zaposlenika i očima organizacije, prateći <strong>7 koraka životnog ciklusa zaposlenika</strong>. Da bi bila certificirana, organizacija mora ostvariti najmanje 75% ukupnih bodova te prag kvalitete u 10 ključnih tema.</p>
  </div>
    `,
  en: `<div style="left: 45px; position: relative; top: 35px; width: 325px;">
  <h2>Employer Partner <span style="color:#1f72ba">methodology</span></h2>
  <p>We observe the HR system through the eyes of the employees and through the eyes of the organization, following the <strong>7 steps of the employee lifecycle</strong>. To be certified, the organization has to achieve at least 75% of total points, and a quality threshold in 10 key topics.</p>
</div>
    `,
  sr: `<div style="left: 45px; position: relative; top: 35px; width: 325px;">
  <h2>Employer Partner <span style="color:#1f72ba">metodologija</span></h2>
  <p>HR sustav promatramo očima zaposlenika i očima organizacije, prateći <strong>7 koraka životnog ciklusa zaposlenika</strong>. Da bi bila certificirana, organizacija mora ostvariti najmanje 75% ukupnih bodova te prag kvalitete u 10 ključnih tema.</p>
</div>
    `,
};

const abAboutCertificate = {
  hr: `<div style="left: 360px; position: relative; top: 15px; width: 350px;">
  <h2>Što je <span style="color:#c98d39">Above and Beyond</span> certifikat?</h2>
  <p>Kako bi prepoznali organizacije koje predvode pozitivne promjene u HR svijetu i čije su HR prakse u skladu s najboljom svjetskom praksom, stručnjaci SELECTIO Grupe pokrenuli su Above and Beyond certifikat.<br /><br />Ovo <strong>posebno priznanje</strong> SELECTIO dodjeljuje poslodavcima koji su pokazali <strong>kontinuitet u vrhunskoj kvaliteti</strong> u <strong>svim područjima</strong> upravljanja ljudskim resursima.</p>
</div>
  `,
  en: `<div style="left: 360px; position: relative; top: 15px; width: 350px;">
  <h2>What is the <span style="color:#c98d39">Above and Beyond</span> certificate?</h2>
  <p>In order to recognize organizations that lead in positive changes in the HR world, whose HR practices are in line with the best global practices, SELECTIO Group experts launched the Above and Beyond certificate. SELECTIO gives this <strong>special recognition</strong> to employers who have demonstrated <strong>continuous top quality</strong> in <strong>all areas</strong> of human resources management.</p>
</div>
  `,
  sr: `<div style="left: 360px; position: relative; top: 15px; width: 350px;">
  <h2>Što je <span style="color:#c98d39">Above and Beyond</span> certifikat?</h2>
  <p>Kako bi prepoznali organizacije koje predvode pozitivne promjene u HR svijetu i čije su HR prakse u skladu s najboljom svjetskom praksom, stručnjaci SELECTIO Grupe pokrenuli su Above and Beyond certifikat.<br /><br />Ovo <strong>posebno priznanje</strong> SELECTIO dodjeljuje poslodavcima koji su pokazali <strong>kontinuitet u vrhunskoj kvaliteti</strong> u <strong>svim područjima</strong> upravljanja ljudskim resursima.</p>
</div>
  `,
};

const abEpMethodology = {
  hr: `<div style="left: 430px; position: relative; top: 5px; width: 350px;">
  <h2>Kako je Above and Beyond povezan s Employer Partner <span style="color:#c98d39">metodologijom</span>?</h2>
  <p>Above and Beyond kategorije inkorporirane su u svih 7 koraka životnog ciklusa zaposlenika. To znači da organizacija mora dokazati izvrsnost u <strong>svim fazama životnog ciklusa zaposlenika</strong> da bi dobila titulu Above and Beyond.</p>
</div>
  `,
  en: `<div style="left: 430px; position: relative; top: 5px; width: 350px;">
  <h2>How is Above and Beyond connected with Employer Partner <span style="color:#c98d39">methodology</span>?</h2>
  <p>Above and Beyond categories are embodied in all 7 steps of the employee lifecycle. This means that to go Above and Beyond, the organization must prove excellence in all <strong>steps of the employee lifecycle</strong>.</p>
</div>
  `,
  sr: `<div style="left: 430px; position: relative; top: 5px; width: 350px;">
  <h2>Kako je Above and Beyond povezan s Employer Partner <span style="color:#c98d39">metodologijom</span>?</h2>
  <p>Above and Beyond kategorije inkorporirane su u svih 7 koraka životnog ciklusa zaposlenika. To znači da organizacija mora dokazati izvrsnost u <strong>svim fazama životnog ciklusa zaposlenika</strong> da bi dobila titulu Above and Beyond.</p>
</div>
  `,
};
const abEvaluation = {
  hr: `<div style="left: 360px; position: relative; top: 5px; width: 370px;">
  <h2>Što <span style="color:#c98d39">evaluiramo</span>?</h2>
  <p>Kako bi stekla Above and Beyond certifikat, organizacija mora postići <strong>natprosječne rezultate u Employer Partner certificiranju</strong>. Osim toga, mora pokazati vrhunske rezultate u barem jednoj od Above and Beyond kategorija. Zbog toga se Above and Beyond procjena sastoji od dodatnih pitanja raspoređenih u 5 kategorija i višeg praga za Employer Partner pitanja.</p>
</div>
  `,
  en: `<div style="left: 360px; position: relative; top: 5px; width: 370px;">
  <h2>What did we <span style="color:#c98d39">evaluate</span>?</h2>
  <p>In order to obtain the Above and Beyond certificate, the organization must achieve <strong>above-average results in the Employer Partner certification</strong>. In addition, it must also show top results in at least one of the Above and Beyond categories. That is why the Above and Beyond assessment consists of additional questions spread over 5 categories and a higher threshold for Employer Partner questions.</p>
</div>
  `,
  sr: `<div style="left: 360px; position: relative; top: 5px; width: 370px;">
  <h2>Što <span style="color:#c98d39">evaluiramo</span>?</h2>
  <p>Kako bi stekla Above and Beyond certifikat, organizacija mora postići <strong>natprosječne rezultate u Employer Partner certificiranju</strong>. Osim toga, mora pokazati vrhunske rezultate u barem jednoj od Above and Beyond kategorija. Zbog toga se Above and Beyond procjena sastoji od dodatnih pitanja raspoređenih u 5 kategorija i višeg praga za Employer Partner pitanja.</p>
</div>
  `,
};

const abExecSummary = {
  hr: `<div style="left: 0; position: relative; top: 20px; width: 300px;">
  <h2>Above and Beyond <span style="color:#c98d39">executive summary</span></h2>
  <p>Ovaj <em>executive summary</em> pokazuje u kojim je kategorijama vaša tvrtka postigla Above and Beyond rezultate. Također, izvještaj će pokazati metriku i HR inicijative koje su vas dovele do najprestižnijeg certifikata za HRM.</p>
</div>
  `,
  en: `<div style="left: 0; position: relative; top: 20px; width: 300px;">
  <h2>Above and Beyond <span style="color:#c98d39">Executive summary</span></h2>
  <p>This Executive summary will show in which categories your company reached the Above and Beyond results. It will also show the metrics and HR initiatives that led you to the most prestigious certificate for HRM.</p>
</div>
  `,
  sr: `<div style="left: 0; position: relative; top: 20px; width: 300px;">
  <h2>Above and Beyond <span style="color:#c98d39">executive summary</span></h2>
  <p>Ovaj <em>executive summary</em> pokazuje u kojim je kategorijama vaša tvrtka postigla Above and Beyond rezultate. Također, izvještaj će pokazati metriku i HR inicijative koje su vas dovele do najprestižnijeg certifikata za HRM.</p>
</div>
  `,
};

const abCategories = {
  hr: `<div style="left: 365px; position: relative; top: -60px; width: 330px;">
  <h2>Ostvarili ste Above and Beyond certifikat u sljedećim <span style="color:#c98d39">kategorijama:</span></h2>
  <p>Procijenili smo vaš HR sustav prema životnom ciklusu zaposlenika, od privlačenja i zadržavanja talenata do odlaska i ponovnog zapošljavanja te smo pronašli snažne temelje u HR sustavu. Prepoznali smo da ste Above and Beyond u sljedećim kategorijama:</p>
</div>
  `,
  en: `<div style="left: 365px; position: relative; top: -60px; width: 330px;">
  <h2>You achieved the Above and Beyond certificate in these <span style="color:#c98d39">categories</span></h2>
  <p>We assess your HR system moving through the Employee Lifecycle, from attracting and retaining talents to offboarding and rehiring - in search of true excellence. With this evaluation, we were able to provide the strong foundations in your HR system and we recognized that you are Above and Beyond in:
  </p>
</div>
  `,
  sr: `<div style="left: 365px; position: relative; top: -60px; width: 330px;">
  <h2>Ostvarili ste Above and Beyond certifikat u sljedećim <span style="color:#c98d39">kategorijama:</span></h2>
  <p>Procijenili smo vaš HR sustav prema životnom ciklusu zaposlenika, od privlačenja i zadržavanja talenata do odlaska i ponovnog zapošljavanja te smo pronašli snažne temelje u HR sustavu. Prepoznali smo da ste Above and Beyond u sljedećim kategorijama:</p>
</div>
  `,
};

const abImpact = {
  hr: `<div style="left: 360px; position: relative; top: 5px; width: 370px;">
  <h2>Above and Beyond <span style="color:#c98d39">Impact</span></h2>
  <p>Važno je izgraditi održivo poslovanje koje podržava ravnotežu između poslodavca i zaposlenika uzimajući u obzir <strong>širi kontekst poslovanja.</strong><br />Imati HR usmjeren na <em>impact</em> znači da je vaša organizacija napredovala od tradicionalnih načina rada HR-a prema modernom sustavu u kojem su HR stručnjaci ti koji mjere i prate utjecaj HR inicijativa na <strong>rast poslovanja</strong> i stavove zaposlenika.</p>
</div>
  `,
  en: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Impact</span></h2>
  <p>It is important to build a sustainable business that supports a balance between the employer and employee considering the <strong>broad context of business</strong>. Having a high Impact HR means that your organization has evolved from traditional ways of operating HR towards a modern system in which HR experts are the ones that measure and track the impact of HR initiatives on <strong>business growth</strong> and employee attitudes.</p>
</div>
  `,
  sr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Impact</span></h2>
  <p>Važno je izgraditi održivo poslovanje koje podržava ravnotežu između poslodavca i zaposlenika uzimajući u obzir <strong>širi kontekst poslovanja.</strong><br />Imati HR usmjeren na <em>impact</em> znači da je vaša organizacija napredovala od tradicionalnih načina rada HR-a prema modernom sustavu u kojem su HR stručnjaci ti koji mjere i prate utjecaj HR inicijativa na <strong>rast poslovanja</strong> i stavove zaposlenika.</p>
</div>
  `,
};

const abSatisfaction = {
  hr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Satisfaction</span></h2>
  <p>Održavanje visokog angažmana i zadovoljstva zaposlenika zahtijeva više od dobre plaće i benefita. Zaposlenici žele osjećati poštovanje i povjerenje dok rade u sigurnom okruženju, s dobrim prilikama za učenje i razvoj. Mjerenje različitih oblika zadovoljstva zaposlenika i postizanje visokih rezultata znači da ste uspjeli razviti <strong>jedinstvene i uspješne načine</strong> za postizanje <strong>angažiranosti</strong> i <strong>zadržavanja zaposlenika</strong>, gradeći jaču, stabilniju i profitabilniju budućnost za organizaciju i zaposlenike.</p>
</div>
  `,
  en: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Satisfaction</span></h2>
  <p>Keeping employees engaged and satisfied takes more than just good pay and benefits. Employees want to feel respect and trust while working in a safe environment, with good opportunities to learn and develop. Measuring various forms of employee satisfaction and having high results means that you managed to develop <strong>unique and successful ways</strong> to achieve <strong>engagement</strong> and <strong>retention</strong>, building a stronger, more stable, and profitable future for the organization and employees.</p>
</div>
  `,
  sr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Satisfaction</span></h2>
  <p>Održavanje visokog angažmana i zadovoljstva zaposlenika zahtijeva više od dobre plaće i benefita. Zaposlenici žele osjećati poštovanje i povjerenje dok rade u sigurnom okruženju, s dobrim prilikama za učenje i razvoj. Mjerenje različitih oblika zadovoljstva zaposlenika i postizanje visokih rezultata znači da ste uspjeli razviti <strong>jedinstvene i uspješne načine</strong> za postizanje <strong>angažiranosti</strong> i <strong>zadržavanja zaposlenika</strong>, gradeći jaču, stabilniju i profitabilniju budućnost za organizaciju i zaposlenike.</p>
</div>
  `,
};

const abInclusion = {
  hr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Inclusion</span></h2>
  <p>Okolina u kojoj su prisutne različite osobine ličnosti, rodovi, rase, nacionalnosti i seksualne orijentacije, ali se cijene samo perspektive određenih skupina, može biti raznolika, ali nije uključiva. Imati inkluzivnu organizaciju znači poštivati jedinstvene <strong>potrebe, perspektive i potencijale</strong> svih članova tima. Imajući inkluzivno radno okruženje, organizacija pokazuje <strong>prilagodljiv</strong> pristup i <strong>proaktivne akcije</strong> prema istinskom vrednovanju svih perspektiva.</p>
</div>
  `,
  en: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Inclusion</span></h2>
  <p>An environment where many different personality traits, genders, races, nationalities, and sexual orientations are present but only the perspectives of certain groups are valued may be diverse, but it is not inclusive. Having inclusive organization means respecting the unique <strong>needs, perspectives, and potential</strong> of all team members. By having an inclusive work environment organization shows an <strong>adaptive</strong> approach and <strong>proactive actions</strong> towards truly valuing all perspectives.</p>
</div>
  `,
  sr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Inclusion</span></h2>
  <p>Okolina u kojoj su prisutne različite osobine ličnosti, rodovi, rase, nacionalnosti i seksualne orijentacije, ali se cijene samo perspektive određenih skupina, može biti raznolika, ali nije uključiva. Imati inkluzivnu organizaciju znači poštivati jedinstvene <strong>potrebe, perspektive i potencijale</strong> svih članova tima. Imajući inkluzivno radno okruženje, organizacija pokazuje <strong>prilagodljiv</strong> pristup i <strong>proaktivne akcije</strong> prema istinskom vrednovanju svih perspektiva.</p>
</div>
  `,
};

const abInnovation = {
  hr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Innovation</span></h2>
  <p>Transformacija poslovanja i nove tehnologije tjeraju organizacije da budu inovativne. Za HR to znači prilagodbu na promjenjive zahtjeve organizacije i zaposlenika. No, imati inovativan HR znači <strong>predviđati buduće potrebe</strong> i okolnosti, a ne samo <strong>pronaći odgovor</strong> na promjenjivu sadašnju situaciju. Da bi imala inovativan HR, organizacija mora imati <strong>povjerenja</strong> u ljude i <strong>otvorenost</strong> za nove ideje.</p>
</div>
  `,
  en: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Innovation</span></h2>
  <p>Business transformation and new technologies force organizations to be innovative. For HR that means implementing changing requirements of the organization and its workforce. Having innovative HR means <strong>anticipating future needs</strong> and circumstances rather than simply <strong>finding a response</strong> to a changing present situation. To have innovative HR the organization must have <strong>trust</strong> in people and <strong>openness</strong> to new ideas. </p>
</div>
  `,
  sr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Innovation</span></h2>
  <p>Transformacija poslovanja i nove tehnologije tjeraju organizacije da budu inovativne. Za HR to znači prilagodbu na promjenjive zahtjeve organizacije i zaposlenika. No, imati inovativan HR znači <strong>predviđati buduće potrebe</strong> i okolnosti, a ne samo <strong>pronaći odgovor</strong> na promjenjivu sadašnju situaciju. Da bi imala inovativan HR, organizacija mora imati <strong>povjerenja</strong> u ljude i <strong>otvorenost</strong> za nove ideje.</p>
</div>
  `,
};

const abFuture = {
  hr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Future</span></h2>
  <p>Uspješne organizacije usmjerene su na stvaranje <strong>održivog poslovanja</strong> i definiranje dugoročnih strategija za ulaganje svojih resursa u projekte koji će se isplatiti u budućnosti. Imati HR usmjeren na budućnost znači fokusirati se na kontinuirani <strong>razvoj zaposlenika</strong>, nudeći zaposlenicima različite oblike razvoja na radnom mjestu kao što su proširenje posla, rotacija poslova kao i prilike za <strong><em>reskilling</em></strong> i <strong><em>upskilling</em></strong>. Uspješno upravljanje znanjem u organizaciji prvi je korak prema održivom poslovanju.</p>
</div>
  `,
  en: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Future</span></h2>
  <p>Successful organizations focus on creating and maintaining a <strong>sustainable busines</strong> and defining long-term strategies to invest their resources in projects that will pay off in the future. Having a future-focused HR means focusing on continuous <strong>employee development</strong>, offering employees various forms of on-the-job development such as job enlargement, job rotation as well as <strong>reskilling</strong> and <strong>upskilling</strong> opportunities. Successful knowledge management in the organization is the first step towards sustainable business.</p>
</div>
  `,
  sr: `<div style="left: 340px; position: relative; top: 5px; width: 400px;">
  <h2>Above and Beyond <span style="color:#c98d39">Future</span></h2>
  <p>Uspješne organizacije usmjerene su na stvaranje <strong>održivog poslovanja</strong> i definiranje dugoročnih strategija za ulaganje svojih resursa u projekte koji će se isplatiti u budućnosti. Imati HR usmjeren na budućnost znači fokusirati se na kontinuirani <strong>razvoj zaposlenika</strong>, nudeći zaposlenicima različite oblike razvoja na radnom mjestu kao što su proširenje posla, rotacija poslova kao i prilike za <strong><em>reskilling</em></strong> i <strong><em>upskilling</em></strong>. Uspješno upravljanje znanjem u organizaciji prvi je korak prema održivom poslovanju.</p>
</div>
  `,
};

export {
  audit,
  elc,
  forEmployer,
  info,
  officialConfirmation,
  whatsNext,
  abAbout,
  abAboutCerifiers,
  abMethodology,
  abAboutCertificate,
  abEpMethodology,
  abEvaluation,
  abExecSummary,
  abCategories,
  abImpact,
  abSatisfaction,
  abInclusion,
  abInnovation,
  abFuture,
};
